export const ResponsivenessUtils = {
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    }
  },
  methods: {
    breakPointValues(smValue, mdValue, lgValue, xlValue) {
      let s = this.screenWidth;
      return s < 600
        ? smValue
        : s < 961
        ? mdValue
        : s < 1903
        ? lgValue
        : xlValue;
    }
  }
};

export const apiCallUtils = {
  methods: {
    async waitingCall(callback, waitVariable, waitKey, opt = {}) {
      if (waitVariable[waitKey] === true) return;
      try {
        this.$set(waitVariable, waitKey, true);
        await callback();
      } catch (e) {
        if (e.cause !== undefined)
          this.$root.$emit("alert-access", {
            message: e.message,
            cause: e.cause,
            url: e.url
          });
        else this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.$set(waitVariable, waitKey, false);
      }
    }
  }
};
