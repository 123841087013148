<template>
  <v-card
    flat
    class="clickable pb-5 pr-5 pt-4 pl-0 grid grid-cols-12 grid-rows-3 border border-solid border-default"
    :height="sizes.height"
    :color="isSelected ? 'blue lighten-5' : ''"
    @click="$emit('click', plan['id'])"
  >
    <div
      class="grid-col-span-2 grid-row-span-3 d-flex align-center justify-center"
    >
      <v-simple-checkbox color="primary" :value="isCurrent"></v-simple-checkbox>
    </div>
    <div
      class="grid-col-span-6 grid-row-span-2 d-flex align-center justify-start"
    >
      <span
        class="ft-bold standard--text xl:text-xl lg:text-lg md:text-base sm:text-sm"
        >{{ plan["title"] }}</span
      >
    </div>
    <div
      class="grid-col-span-4 grid-row-span-2 d-flex align-center justify-end"
    >
      <span
        class="ft-bold standard--text xl:text-xl lg:text-lg md:text-base sm:text-sm"
        >{{ plan["price"] }}</span
      >
    </div>
    <div
      class="grid-col-span-6 grid-row-span-1 d-flex align-center justify-start"
    >
      <span class="grey--text xl:text-sm lg:text-sm md:text-sm sm:text-xs">{{
        plan["access"]
      }}</span>
    </div>
    <div
      class="grid-col-span-4 grid-row-span-1 d-flex align-center justify-end"
    >
      <span class="grey--text xl:text-sm lg:text-sm md:text-sm sm:text-xs">{{
        plan["validity"]
      }}</span>
    </div>
  </v-card>
</template>

<script>
import { ResponsivenessUtils } from "/global/utils/mixins";
import { scrollTo } from "/global/utils/helpers";

export default {
  mixins: [ResponsivenessUtils],
  props: {
    plan: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isCurrent() {
      return this.plan?.id === this.$store.state.user.user["current_plan"];
    },
    sizes() {
      return {
        height: this.breakPointValues(80, 80, 100, 100)
      };
    }
  },
  mounted() {
    if (this.isCurrent) {
      scrollTo(this.$attrs.id, { block: "center" });
    }
  }
};
</script>

<style scoped lang="scss"></style>
