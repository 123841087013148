var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass:
        "clickable pb-5 pr-5 pt-4 pl-0 grid grid-cols-12 grid-rows-3 border border-solid border-default",
      attrs: {
        flat: "",
        height: _vm.sizes.height,
        color: _vm.isSelected ? "blue lighten-5" : "",
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click", _vm.plan["id"])
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "grid-col-span-2 grid-row-span-3 d-flex align-center justify-center",
        },
        [
          _c("v-simple-checkbox", {
            attrs: { color: "primary", value: _vm.isCurrent },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "grid-col-span-6 grid-row-span-2 d-flex align-center justify-start",
        },
        [
          _c(
            "span",
            {
              staticClass:
                "ft-bold standard--text xl:text-xl lg:text-lg md:text-base sm:text-sm",
            },
            [_vm._v(_vm._s(_vm.plan["title"]))]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "grid-col-span-4 grid-row-span-2 d-flex align-center justify-end",
        },
        [
          _c(
            "span",
            {
              staticClass:
                "ft-bold standard--text xl:text-xl lg:text-lg md:text-base sm:text-sm",
            },
            [_vm._v(_vm._s(_vm.plan["price"]))]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "grid-col-span-6 grid-row-span-1 d-flex align-center justify-start",
        },
        [
          _c(
            "span",
            {
              staticClass:
                "grey--text xl:text-sm lg:text-sm md:text-sm sm:text-xs",
            },
            [_vm._v(_vm._s(_vm.plan["access"]))]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "grid-col-span-4 grid-row-span-1 d-flex align-center justify-end",
        },
        [
          _c(
            "span",
            {
              staticClass:
                "grey--text xl:text-sm lg:text-sm md:text-sm sm:text-xs",
            },
            [_vm._v(_vm._s(_vm.plan["validity"]))]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }