var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-6 mt-6" },
    [
      _vm.loading.status
        ? _c(
            "v-card",
            {
              staticClass: "elevation-0 d-flex justify-center align-center",
              attrs: { height: _vm.sizes.list.height },
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" },
              }),
            ],
            1
          )
        : _vm.showNoItems
        ? _c(
            "v-card",
            { staticClass: "elevation-0" },
            [
              _c("no-content-component", {
                attrs: { message: "No transactions" },
              }),
            ],
            1
          )
        : _c(
            "v-card",
            { class: _vm.classes.transactionCard, attrs: { flat: "" } },
            [
              _vm.paging.status
                ? _c(
                    "v-card",
                    {
                      staticClass:
                        "elevation-0 d-flex justify-center align-center",
                      attrs: { height: _vm.sizes.list.height },
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" },
                      }),
                    ],
                    1
                  )
                : _c(
                    "v-card",
                    {
                      staticClass: "flex-column overflow-x-hidden elevation-0",
                      attrs: { height: _vm.sizes.list.height },
                    },
                    _vm._l(_vm.items, function (tr, i) {
                      return _c(
                        "v-card",
                        {
                          key: i,
                          staticClass: "elevation-0 border-default border",
                          attrs: { outlined: "", color: "#F8F8FA" },
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "ma-0" },
                            [
                              _vm.screenWidth >= 600
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex flex-column justify-center align-center",
                                      attrs: { cols: _vm.cols.image },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { attrs: { flat: "", width: "43" } },
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              height: "43",
                                              width: "43",
                                              src: require("#ef/payment-transaction/assets/transaction_image.svg"),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.screenWidth <= 960
                                        ? _c("div", {
                                            staticStyle: { height: "50%" },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                { attrs: { cols: _vm.cols.middle } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "d-flex flex-column justify-center pl-0",
                                          attrs: {
                                            cols: _vm.cols.inner.title,
                                            order: _vm.order.inner.title,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-truncate xl:text-base lg:text-base md:text-base sm:text-base",
                                            },
                                            [
                                              _c("b", [
                                                _vm._v(_vm._s(tr.title)),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-truncate xl:text-sm lg:text-sm md:text-sm sm:text-sm",
                                            },
                                            [
                                              _vm._v(
                                                " Date: " +
                                                  _vm._s(
                                                    _vm.dateFormat(
                                                      tr.transaction_time
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "d-flex flex-column justify-center pl-0",
                                          attrs: {
                                            cols: _vm.cols.inner.method,
                                            order: _vm.order.inner.method,
                                          },
                                        },
                                        [
                                          _vm.hasMethod(tr)
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pa-0 xl:text-sm lg:text-sm md:text-sm sm:text-sm",
                                                },
                                                [
                                                  _vm._v(
                                                    " Method: " +
                                                      _vm._s(
                                                        tr.payment_method
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.hasSessiondId(tr)
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pa-0 text-truncate xl:text-sm lg:text-sm md:text-sm sm:text-sm",
                                                },
                                                [
                                                  _vm._v(
                                                    " Transaction ID: " +
                                                      _vm._s(
                                                        tr.payment_method_session_id
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pa-0 xl:text-sm lg:text-sm md:text-sm sm:text-sm",
                                                },
                                                [_vm._v(" Bypassed ")]
                                              ),
                                        ]
                                      ),
                                      _vm.screenWidth >= 600
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex flex-column justify-center pl-0",
                                              attrs: {
                                                cols: _vm.cols.inner.amount,
                                                order: _vm.order.inner.amount,
                                              },
                                            },
                                            [
                                              _vm.wasSuccessful(tr)
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-end pl-0 primary--text xl:text-lg lg:text-lg md:text-lg sm:text-lg",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.numberFormat(
                                                              tr.amount
                                                            )
                                                          ) +
                                                          _vm._s(
                                                            _vm.currencySymbol[
                                                              tr.currency
                                                            ]
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm.wasAborted(tr)
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-end pl-0 xl:text-lg lg:text-lg md:text-lg sm:text-lg decline--text",
                                                    },
                                                    [_vm._v(" Aborted ")]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-end pl-0 xl:text-lg lg:text-lg md:text-lg sm:text-lg decline--text",
                                                    },
                                                    [_vm._v(" Failed ")]
                                                  ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex flex-column justify-center pa-0",
                                  attrs: { cols: _vm.cols.action },
                                },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: { rounded: "sm" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { icon: "" } },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { size: "28" } },
                                                      [
                                                        _vm._v(
                                                          "mdi-dots-vertical"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        {
                                          staticClass:
                                            "elevation-0 rounded border-solid border-default border-1-2 py-0",
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass:
                                                "px-2 xl:text-sm lg:text-sm md:text-sm sm:text-sm",
                                              class: {
                                                inert:
                                                  !tr.payment_method_session_id,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.copySessionId(
                                                    tr.payment_method_session_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  style: `color: ${
                                                    tr.payment_method_session_id
                                                      ? "#545454"
                                                      : "#bdbdbd"
                                                  }`,
                                                },
                                                [_vm._v("  Copy ID ")]
                                              ),
                                            ]
                                          ),
                                          _c("v-divider", {
                                            staticClass: "ma-0 divider-style",
                                          }),
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass:
                                                "px-2 xl:text-sm lg:text-sm md:text-sm sm:text-sm",
                                              class: {
                                                inert: !_vm.wasSuccessful(tr),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showReceipt(
                                                    tr.transaction_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  style: `color: ${
                                                    _vm.wasSuccessful(tr)
                                                      ? "#545454"
                                                      : "#bdbdbd"
                                                  }`,
                                                },
                                                [_vm._v("  View receipt ")]
                                              ),
                                            ]
                                          ),
                                          _c("v-divider", {
                                            staticClass: "ma-0 divider-style",
                                          }),
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass:
                                                "px-2 xl:text-sm lg:text-sm md:text-sm sm:text-sm",
                                              class: {
                                                inert: !_vm.wasSuccessful(tr),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.downloadReceipt(
                                                    tr.transaction_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  style: `color: ${
                                                    _vm.wasSuccessful(tr)
                                                      ? "#545454"
                                                      : "#bdbdbd"
                                                  }`,
                                                },
                                                [_vm._v("  Download ")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.screenWidth <= 960
                                    ? _c("div", {
                                        staticStyle: { height: "50%" },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
              _vm.showPaginator
                ? _c(
                    "div",
                    { staticClass: "mt-5" },
                    [
                      _c("v-pagination", {
                        attrs: {
                          length: _vm.pages,
                          "total-visible": _vm.breakPointValues(2, 5, 7, 7),
                          color: "primary",
                        },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          staticClass: "rounded",
          attrs: { width: "75%" },
          model: {
            value: _vm.showReceiptMenu,
            callback: function ($$v) {
              _vm.showReceiptMenu = $$v
            },
            expression: "showReceiptMenu",
          },
        },
        [_c("receipt-dialog", { attrs: { contents: _vm.receiptContent } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }