<template>
  <div class="card pa-5">
    <div
      v-if="loading.status"
      class="fill-height d-flex align-center justify-center"
    >
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <div v-else class="fill-height d-flex flex-column">
      <div>
        <h3 class="primary--text xl:text-xl">{{ plan["title"] }}</h3>
      </div>
      <div>
        <h2 class="pt-4 pb-2 standard--text xl:text-3xl">
          {{ plan["price"] }}
        </h2>
        <p class="grey--text xl:text-sm">
          {{ plan["validity"] }}
        </p>
      </div>
      <div class="flex-fill">
        <p class="py-3 ft-medium standard--text xl:text-base">Get access to:</p>
        <div
          :style="`max-height: ${sizes.features}px`"
          class="features-section pb-4 d-flex flex-column overflow-y-auto"
        >
          <div v-for="(feature, i) in features" :key="i">
            <v-icon color="primary" class="mr-2">mdi-check-all</v-icon>
            <span class="ft-light xl:text-base">{{ feature }}</span>
          </div>
        </div>
      </div>
      <v-btn
        v-if="plan['purchasable']"
        depressed
        color="primary"
        class="white--text"
        :disabled="!available"
        @click="upgradePlan()"
        >{{
          !available
            ? "Currently Unavailable"
            : planId === String(currentPlanId)
            ? remainingText
            : "Upgrade"
        }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { ResponsivenessUtils, apiCallUtils } from "/global/utils/mixins";
import { PaymentFetch } from "#ef/payment-transaction/mixins.js";
import moment from "moment";

export default {
  mixins: [PaymentFetch, ResponsivenessUtils, apiCallUtils],
  props: {
    planId: {
      type: String,
      default: ""
    },
    available: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: {}
    };
  },
  computed: {
    currentPlanId() {
      return this.$store.state.user.user["current_plan"];
    },
    plan() {
      let x = this.$store.getters["transactionEF/plans"];
      if (!this.planId) return {};
      else {
        return x.find(item => item.id === this.planId);
      }
    },
    features() {
      return this.$store.getters["transactionEF/features"][this.planId];
    },
    sizes() {
      return {
        features: this.breakPointValues(200, 200, 240, 280)
      };
    },
    remainingText() {
      if (!this.plan?.delta?.amount) return "Unlimited";
      let planStart = Date.parse(this.$store.state.user.user["plan_start"]);
      let planEnd = moment(planStart)
        .add(this.plan.delta)
        .toDate();
      let today = new Date();
      return `${Math.floor(
        (planEnd - today) / (1000 * 3600 * 24)
      )} day remaining`;
    }
  },
  watch: {
    planId: {
      immediate: true,
      async handler(value) {
        if (value && value?.length > 0) {
          await this.fetchFeatures();
        }
      }
    }
  },
  methods: {
    async fetchFeatures() {
      let planId = this.planId;
      await this.waitingCall(
        async () => {
          await this.fetchContent("getFeatures", { planId });
        },
        this.loading,
        "status"
      );
    },
    upgradePlan() {
      if (this.planId === this.currentPlanId) return;
      this.$emit("upgrade", this.planId);
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  box-sizing: border-box;
  border: 1px solid $primary;
  border-radius: 4px;
  height: 552px;
}

.features-section {
  scrollbar-width: auto;
  scrollbar-color: $primary white;
}
</style>
