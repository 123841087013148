var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card pa-5" }, [
    _vm.loading.status
      ? _c(
          "div",
          { staticClass: "fill-height d-flex align-center justify-center" },
          [
            _c("v-progress-circular", {
              attrs: { color: "primary", indeterminate: "" },
            }),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "fill-height d-flex flex-column" },
          [
            _c("div", [
              _c("h3", { staticClass: "primary--text xl:text-xl" }, [
                _vm._v(_vm._s(_vm.plan["title"])),
              ]),
            ]),
            _c("div", [
              _c(
                "h2",
                { staticClass: "pt-4 pb-2 standard--text xl:text-3xl" },
                [_vm._v(" " + _vm._s(_vm.plan["price"]) + " ")]
              ),
              _c("p", { staticClass: "grey--text xl:text-sm" }, [
                _vm._v(" " + _vm._s(_vm.plan["validity"]) + " "),
              ]),
            ]),
            _c("div", { staticClass: "flex-fill" }, [
              _c(
                "p",
                { staticClass: "py-3 ft-medium standard--text xl:text-base" },
                [_vm._v("Get access to:")]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "features-section pb-4 d-flex flex-column overflow-y-auto",
                  style: `max-height: ${_vm.sizes.features}px`,
                },
                _vm._l(_vm.features, function (feature, i) {
                  return _c(
                    "div",
                    { key: i },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { color: "primary" } },
                        [_vm._v("mdi-check-all")]
                      ),
                      _c("span", { staticClass: "ft-light xl:text-base" }, [
                        _vm._v(_vm._s(feature)),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
            _vm.plan["purchasable"]
              ? _c(
                  "v-btn",
                  {
                    staticClass: "white--text",
                    attrs: {
                      depressed: "",
                      color: "primary",
                      disabled: !_vm.available,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.upgradePlan()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        !_vm.available
                          ? "Currently Unavailable"
                          : _vm.planId === String(_vm.currentPlanId)
                          ? _vm.remainingText
                          : "Upgrade"
                      ) + " "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }